import React from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import '../footer/Footer.css'
import '../footer/MobileFooter.css'


const Footer = () => {
  return (
    <>
    <div className='footpad' id='footer'>
    <Container >
        <Row>
          <Col lg="12" className='py-3'>
              <div className='foot-title spacegrotesk'> Zentexus <span className='com'>.Com</span></div>
          </Col>
          <Col lg="6" className="py-3">
            <div className='watch bold'>
              Get A Solutions For Digital
            </div>
            <Row>
              <Col lg="6" className='py-3'>
                  <div className='foot-box text-white'>
                      <div className='spacegrotesk fs-20'>info@zentexus.com</div>
                  </div>
              </Col>
              <Col lg="6" className='py-3'>
                  <div className='foot-box text-white'>
                      <div className='spacegrotesk fs-20'>+91 93637 62876</div>
                  </div>
              </Col>
            </Row>
          </Col>
          <Col lg="6" className="py-3">
              <Row>
                <Col lg="6" className='py-3'>
                  <div className='py-3'>
                    <div className='pb-4 bold fs-30'> Quick Link</div> 
                      <ul className='text-white foot-link spacegrotesk'>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/services">Service</Link></li>
                        <li><Link to="/contactus">Contact Us</Link></li>
                      </ul>
                  </div>
                </Col>
                <Col lg="6" className='py-3'>
                  <div className='py-3'>
                    <div className='pb-4 bold fs-30'> Our Services</div> 
                      <ul className='text-white foot-link spacegrotesk'>
                        <li><Link to="/"> Web Development</Link></li>
                        <li><Link to="/">App Development</Link></li>
                        <li><Link to="/">Website Maintanance</Link></li>
                        <li><Link to="/">Digital Marketing</Link></li>
                      </ul>
                  </div>
                </Col>
              </Row>
          </Col>
          <Col lg="4" className='py-3'>
            <div  className="smallfont spacegrotesk"> CopyRights &copy; @2024. Zentexus <span className='com'>.com</span> </div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  )
}

export default Footer