import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../home/Home.css";
import "../home/MobileHome.css";
import { MdArrowForwardIos } from "react-icons/md";

import { RiUserHeartLine } from "react-icons/ri";
import { RiUserSettingsLine } from "react-icons/ri";
import { MdOutlineContactSupport } from "react-icons/md";
import { MdOutlineDesignServices } from "react-icons/md";
import Carousel from "../../components/carousel/carousel";
import OurService from "../pagecomponents/OurService";
const Home = () => {
  useEffect(() => {
    const textElement = document.querySelector(".circle-text p");
    if (textElement) {
      const text = textElement.innerText;
      textElement.innerHTML = text
        .split("")
        .map(
          (char, i) =>
            `<span style="transform:rotate(${i * 5.1}deg)">${char}</span>`
        )
        .join("");
    }
  }, []);
  return (
    <>
      {/* banner section start */}
      <>
        <div className="padding">
          <Container className="px-lg-5 px-4">
            <Row className="">
              <Col lg="8" className="py-3">
                <div className="banner-text bold">
                  <div>Solutions Beyond</div>
                  <div className="italic">Expectations</div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
      {/* banner section end */}
      {/* banner start */}
      <>
        <div className="padding">
          <Container className="px-lg-5 px-4">
            <Row className="">
              <Col lg="12" className="py-3">
                <div className="text-center banner-image">
                  <img
                    src={require("../../assets/images/banner.jpg")}
                    className="img-fluid w-100"
                    alt="website developer"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
      {/* banner end */}
      {/* home about start */}
      <>
        <div className="padding">
          <Container className="px-4 px-lg-5">
            <Row className="">
              <Col lg="4" className="py-3 align-self-center ">
                <div className="text-center">
                  <div className="circle-letter">
                    <div className="circle-text">
                      <p>
                        {" "}
                        S t u n n i n g . R e s p o n s i v e . S e c u r e W e
                        b s i t e s .
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="8">
                <div className="bold com d-flex title">
                  {" "}
                  <div>
                    <MdArrowForwardIos />
                  </div>{" "}
                  <div>About Company</div>
                </div>
                <div className="spacegrotesk lh-2">
                  <div className="about-quote">
                    "Unleashing Innovative Technology Solutions for Your
                    Business"
                  </div>
                  <p className="fs-20">
                    At <span className="bold com">Zentexus</span>, we believe
                    that a website is more than just a digital presence. It's an
                    opportunity to tell your story, showcase your brand, and
                    connect with your audience. Our team of expert web
                    developers, designers, and strategists work together to
                    craft custom websites that drive results. Whether you're
                    looking to revamp your existing site or launch a new one,
                    we'll help you create a stunning, responsive, and secure
                    online presence that exceeds your expectations.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
      {/* home about end */}
      {/* one line quote */}
      <>
        <Container className="py-5 px-lg-5">
          <Row className="justify-content-center">
            <Col lg="12" className="py-3">
              <div className="banner-text bold">
                <div>digital Crafting</div>
                <div className="italic">Partner</div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
      {/* one line quote end */}
      {/* our service start */}
      <OurService />
      {/* our service end */}
      {/* why choose start */}
      <>
        <div className="padding">
          <Container className="padding px-lg-5 px-4">
            <Row>
              <Col lg="12" className="py-3">
                <div className="title com bold text-center">
                  Why We Choose ?
                </div>
              </Col>
              <Col lg="5" className="py-3 align-self-center">
                <div className="">
                  <img
                    src={require("../../assets/images/whyimage.jpg")}
                    className="img-fluid"
                    alt="website hosting and domain"
                    title="website hosting and domain"
                  />
                </div>
              </Col>
              <Col lg="7" className="py-3">
                <div className="about-quote spacegrotesk">
                  Discover why businesses trust Zentexus Technologies for their
                  technology needs.
                </div>
                <div className="spacegrotesk lh-2">
                  At Zentexus Technologies, we bring over a decade of experience
                  and a team of dedicated experts to deliver innovative and
                  tailored technology solutions. Our commitment to excellence
                  ensures that we use the latest technologies to drive your
                  business forward. We pride ourselves on our client-centric
                  approach, understanding your unique needs, and providing
                  ongoing support to ensure your success. With a proven track
                  record of successful projects and satisfied clients, we
                  consistently adhere to the highest standards of quality and
                  integrity.
                </div>
                <Row>
                  <Col lg="6" className="py-3 align-self-center">
                    <div className="d-flex align-content-center align-items-center">
                      <div className="me-2">
                        <RiUserSettingsLine size={34} color="darkgreen" />
                      </div>
                      <div className="spacegrotesk fs-20 whites">
                        {" "}
                        Expertise and Experience
                      </div>
                    </div>
                  </Col>
                  <Col lg="6" className="py-3">
                    <div className="d-flex align-content-center align-items-center">
                      <div className="me-2">
                        <RiUserHeartLine size={34} color="darkgreen" />
                      </div>
                      <div className="spacegrotesk fs-20">
                        {" "}
                        Client-Centric Approach
                      </div>
                    </div>
                  </Col>
                  <Col lg="6" className="py-3">
                    <div className="d-flex align-content-center align-items-center">
                      <div className="me-2 ">
                        <MdOutlineDesignServices size={34} color="darkgreen" />
                      </div>
                      <div className="spacegrotesk fs-20">
                        {" "}
                        Innovative Solutions
                      </div>
                    </div>
                  </Col>
                  <Col lg="6" className="py-3">
                    <div className="d-flex align-content-center align-items-center">
                      <div className="me-2">
                        <MdOutlineContactSupport size={34} color="darkgreen" />
                      </div>
                      <div className="spacegrotesk fs-20">Reliable Support</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </>
      {/* why choose end */}
      {/* technologies start */}
      <>
        <Container className="py-5" fluid>
          <Row>
            <Col lg="12" className="py-4">
              <h4 className="text-center bold title com">
                {" "}
                Technologies We Expertise
              </h4>
            </Col>
            <Col lg="12">
              <Carousel />
            </Col>
          </Row>
        </Container>
      </>
      {/* technologies start */}
    </>
  );
};

export default Home;
