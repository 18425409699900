import React from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import { RiSettings2Fill } from "react-icons/ri";
const OurCompliance = () => {
  return (
    <>
               <div className='bg-light-green'>
            <Container className='margin'>
                <Row>
                    <Col lg="3" className='py-3'>
                        <div className='d-flex align-self-center justify-content-center'>
                            <div className='setting'><RiSettings2Fill /></div>
                            <div className='setting-text'>Innovation</div>
                        </div>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <div className='d-flex align-self-center justify-content-center'>
                            <div className='setting'><RiSettings2Fill /></div>
                            <div className='setting-text'>Integrity</div>
                        </div>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <div className='d-flex align-self-center justify-content-center'>
                            <div className='setting'><RiSettings2Fill /></div>
                            <div className='setting-text'>Customer Focus</div>
                        </div>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <div className='d-flex align-self-center justify-content-center'>
                            <div className='setting'><RiSettings2Fill /></div>
                            <div className='setting-text'>Excellence</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
  )
}

export default OurCompliance