import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
const HowWeWork = () => {
  return (
    <>
      <div className="padding">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" className="py-3 text-center ">
              <div className="pb-5 text-whitee">
                <h5 className="com bold title"> How We Work</h5>
              </div>
            </Col>
            <Col lg="3" md="3" xs="12" sm="12">
              <div className="inpect-box plan-bg">
                <div className="inspect-content ">
                  <div className="spacegrotesk py-4 text-whitee"> Step 1</div>
                  <hr />
                  <h4 className="com bold text-whitee">Analyzing</h4>
                  <p className="spacegrotesk text-whitee">
                    Comprehensive website inspection is essential for ensuring
                    peak performance and development readiness.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              lg="1"
              md="1"
              xs="12"
              sm="12"
              className="text-center align-self-center"
            >
              <div className="d-lg-block d-none">
                {" "}
                <FaChevronRight color="darkgreen" />
              </div>
              <div className="d-lg-none d-block">
                {" "}
                <FaChevronDown color="darkgreen" />
              </div>
            </Col>
            <Col lg="3" md="3" xs="12" sm="12">
              <div className="inpect-box plan-bg ">
                <div className="inspect-content">
                  <div className="spacegrotesk py-4 text-whitee"> Step 2</div>
                  <hr />
                  <h4 className=" bold text-whitee"> Optimizing</h4>
                  <p className="spacegrotesk text-whitee">
                    Optimizing the website with targeted strategies ensures all
                    its needs are met, enhancing overall performance.
                  </p>
                </div>
              </div>
            </Col>
            <Col
              lg="1"
              md="1"
              xs="12"
              sm="12"
              className="text-center align-self-center"
            >
              <div className="d-lg-block d-none">
                {" "}
                <FaChevronRight color="darkgreen" />
              </div>
              <div className="d-lg-none d-block">
                {" "}
                <FaChevronDown color="darkgreen" />
              </div>
            </Col>
            <Col lg="3" md="3" xs="12" sm="12">
              <div className="inpect-box plan-bg">
                <div className="inspect-content">
                  <div className="spacegrotesk py-4 text-whitee"> Step 3</div>
                  <hr />
                  <h4 className="com bold text-whitee"> Launching</h4>
                  <p className="spacegrotesk text-whitee">
                    Our team will launch your website, ensuring it's visible to
                    your target audience and optimized for growth.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HowWeWork;
