import React from 'react'
import { Form } from 'react-bootstrap'
import '../forms/TextInputform.css'
const TextInputform = ({labelname,type,placeholder,value}) => {
  return (
    <>
        <div className='pb-2 fs-5'>
            {labelname ? <label className='bold'>{labelname}</label> : ""}
        </div>
        <div className='form-icon'>
            <Form.Group>
                <input
                    type={type}
                    placeholder={placeholder}
                    className='form-cntrl w-100 form-control' 
                    value={value}
                />
            </Form.Group>
        </div>
    </>
  )
}
const TextArea = ({labelname,type,placeholder,value}) => {
  return (
    <>
        <div className='pb-2 fs-5'>
            {labelname ? <label className='bold'>{labelname}</label> : ""}
        </div>
        <div className='form-icon'>
            <Form.Group>
                <textarea
                    type={type}
                    placeholder={placeholder}
                    className='form-cntrl w-100 form-control' 
                    value={value}
                />
            </Form.Group>
        </div>
    </>
  )
}

export {TextInputform,TextArea}