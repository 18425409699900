import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { IoDiamondOutline } from "react-icons/io5";
import { FaCode } from "react-icons/fa";
import { BsTools } from "react-icons/bs";
import { BsRocket } from "react-icons/bs";
import { GrHostMaintenance } from "react-icons/gr";
import { IoIosAnalytics } from "react-icons/io";
const WhatWedo = () => {
  return (
    <>
      <div className="padding">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12" xs="12" className="py-3">
              <h5 className="title com spacegrotesk"> What We Do ?</h5>
              <p className="spacegrotesk fs-5 ">
                We craft stunning, user-friendly websites tailored to your
                brand’s unique needs. Our expert team ensures seamless
                functionality and compelling design to drive engagement and
                results. Let us build the perfect digital platform for your
                business—get in touch today!
              </p>
            </Col>
            <Col lg="3" md="6" sm="12" xs="12" className="py-3">
              <div className="wedo-box plan-bg ">
                <div className="py-3">
                  <IoDiamondOutline size={40} />
                </div>
                <h5 className="bold text-whitee">
                  Define Goals and
                  <br /> Requirements
                </h5>
                <hr />
                <p className="spacegrotesk text-whitee">
                  At Zentexus Technologies, we recognize the importance of
                  comprehending the website's purpose, identifying its target
                  audience, and aligning it with specific goals to ensure its
                  success.
                </p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12" xs="12" className="py-3">
              <div className="wedo-box plan-bg">
                <div className="py-3 ">
                  <IoIosAnalytics size={40} />
                </div>
                <h5 className="bold">
                  Research and
                  <br /> Planning
                </h5>
                <hr />
                <p className="spacegrotesk text-whitee">
                  Conduct market research to understand the target audience,
                  analyze competitors, and identify industry trends. Gather
                  valuable insights to inform strategic decisions.
                </p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12" xs="12" className="py-3">
              <div className="wedo-box plan-bg">
                <div className="py-3 ">
                  <FaCode size={40} />
                </div>
                <h5 className="bold">
                  User Experience (UX)
                  <br /> Design
                </h5>
                <hr />
                <p className="spacegrotesk text-whitee">
                  Design wireframes or prototypes to create a user-friendly
                  experience. Ensure the layout is intuitive and engaging for
                  users.
                </p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12" xs="12" className="py-3">
              <div className="wedo-box plan-bg">
                <div className="py-3 ">
                  <BsTools size={40} />
                </div>
                <h5 className="bold">
                  Testing and <br />
                  Optimization
                </h5>
                <hr />
                <p className="spacegrotesk text-whitee">
                  Test thoroughly to identify and fix issues like broken links,
                  browser compatibility, and usability glitches. Ensure a
                  seamless user experience.
                </p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12" xs="12" className="py-3">
              <div className="wedo-box plan-bg">
                <div className="py-3 ">
                  <BsRocket size={40} />
                </div>
                <h5 className="bold">
                  Launch and
                  <br /> Deployment
                </h5>
                <hr />
                <p className="spacegrotesk text-whitee">
                  Host the website and set up the domain and hosting securely.
                  Monitor performance regularly to ensure optimal functionality.
                </p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12" xs="12" className="py-3">
              <div className="wedo-box plan-bg">
                <div className="py-3 ">
                  <GrHostMaintenance size={40} />
                </div>
                <h5 className="bold">
                  Maintenance and <br />
                  Updates
                </h5>
                <hr />
                <p className="spacegrotesk text-whitee">
                  Keep the website updated with fresh content, enhance its
                  features regularly, and promptly fix any technical issues that
                  arise.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WhatWedo;
