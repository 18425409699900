import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../appbar/AppBar.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { MdArrowOutward, MdClose } from "react-icons/md";
import { Button } from 'react-bootstrap';

const AppBar = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  const closeNavbar = () => {
    setExpanded(false);
  };
  return (
    <>
    <Navbar expand="lg" className="nav-bg"  expanded={expanded}>
      <Container className='px-lg-5'>
        <Navbar.Brand as={Link} to="/" className='logo mr-0'>Zentexus<span className='com'>.Com</span></Navbar.Brand>
          <Button className="menu-toggle"  id="menu-toggle" onClick={toggleNavbar}>
                <span class="navbar-toggler-icon"></span>
              </Button>
        <Navbar.Collapse id="nav-bar"  className={`justify-content-end custom-collapse ${expanded ? 'open' : ''}`}>
          <Nav className="mx-auto">
            <Nav.Link as={Link} to="/" className='mx-3' onClick={closeNavbar}>Home</Nav.Link>
            <Nav.Link as={Link} to="/about" className='mx-3' onClick={closeNavbar}>About</Nav.Link>
            <Nav.Link as={Link} to="/services" className='mx-3' onClick={closeNavbar}>Services</Nav.Link>
            <Nav.Link as={Link} to="/contactus" className='mx-3' onClick={closeNavbar}>Contact Us</Nav.Link>
          </Nav>
          <div className='close-btn d-lg-none' onClick={closeNavbar}>
              <MdClose size={30} />
            </div>
        </Navbar.Collapse>
        <div className=' d-lg-block d-none'>
          <Nav.Link as={Link} to="/contactus" className='talk'>
            <div>Let's Talk</div>
            <div><MdArrowOutward /></div>
          </Nav.Link>
        </div>
      </Container>
    </Navbar>
    </>
  );
};

export default AppBar;
