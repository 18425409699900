import React from "react";
import "../servicespage/Service.css";
import { Container, Row, Col } from "react-bootstrap";
import Buttons from "../../components/button/Buttons";
import OurService from "../pagecomponents/OurService";
import HowWeWork from "./HowWeWork";
import WhatWedo from "./WhatWedo";
import OurCompliance from "../pagecomponents/OurCompliance";
import { FaArrowUp } from "react-icons/fa";
import Forms from "../pagecomponents/Forms";
const Service = () => {
  return (
    <>
      <>
        {/* hero section start */}
        <div className="service-bg bg-overlay">
          <Container>
            <Row>
              <Col lg="8" md="6" xs="12">
                <div className="banner-text text-white bold z">
                  <div>Website Development Agency</div>
                </div>
              </Col>
              <Col lg="4" md="6" xs="12">
                <Forms />
              </Col>
            </Row>
          </Container>
        </div>
        {/* hero section end */}
      </>
      {/* our service start */}
      <>
        <OurService />
      </>
      {/* our service end */}

      {/* how we work start */}
      <>
        <HowWeWork />
      </>
      {/* how wewe work end */}
      {/*what we do start */}
      <>
        <WhatWedo />
      </>
      {/* what we do end */}
      <>
        {/* markline start */}
        <OurCompliance />
        {/* markline end */}
      </>
      <>
        {/* work with us  start*/}
        <div className="padding">
          <Container>
            <Row className="justify-content-center ">
              <Col lg="12">
                <div className="title com text-center spacegrotesk">
                  {" "}
                  Work With US{" "}
                </div>
              </Col>
              <Col lg="8">
                <div className="text-center spacegrotesk fs-50">
                  <div>Have Any Projects On Minds ...!</div>
                  <div> Feel Free to Contact Us</div>
                </div>
                <div className=" text-center">
                  <Buttons
                    className="btn bg-light-green"
                    label={
                      <>
                        Connect Today with Zentexus <br /> <FaArrowUp />
                      </>
                    }
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* work with us  end*/}
      </>
    </>
  );
};

export default Service;
