import React from 'react'
import { GoArrowUpRight } from "react-icons/go";
import { Container, Row,Col } from 'react-bootstrap'
const OurService = () => {
  return (
    <>
          <>
       <div className='padding'>
        <Container className='px-lg-5 px-4'>
            <Row className='justify-content-center'>
              <Col lg='12' className='py-4'>
                  <div className='spacegrotesk title com text-center py-4'> Our Services</div>
                    <div></div>
              </Col>
              <Col lg="4" className='py-3'>
                <div className='service-box'>
                  <div className='service-icon'>
                      <img src={require('../../assets/images/web.png')}  className='img-fluid' alt='website developing' title='website developing'/>
                  </div>
                  <div className='go-link'><GoArrowUpRight /> </div>
                  <div className='web-circle'></div>
                  <div className='service-title fs-20 bold py-3'>Web Development</div>
                  <div className='service-desc '>
                    <img src={require('../../assets/images/website.jpg')}  className='img-fluid' alt='website developing' title='website developing'/>
                  </div>
                </div>
              </Col>
              <Col lg="4" className='py-3'>
                <div className='service-box'>
                <div className='service-icon'>
                  <img src={require('../../assets/images/mobile.png')}  className='img-fluid' alt='mobile app developing' title='mobile app developing'/>
                </div>
                <div className='go-link'><GoArrowUpRight /> </div>
                <div className='web-circle'></div>
                <div className='service-title fs-20 bold py-3'>Mobile Development</div>
                  <div className='service-desc'>
                    <img src={require('../../assets/images/mobileapp.jpg')}  className='img-fluid' alt='mobile app developing' title='mobile app developing'/>
                  </div>
                </div>
              </Col>
              <Col lg="4" className='py-3'>
                <div className='service-box'>
                <div className='service-icon'>
                    <img src={require('../../assets/images/dm.png')}  className='img-fluid' alt='digital marketing' title='digital marketing'/>
                </div>
                <div className='go-link'><GoArrowUpRight /> </div>
                <div className='web-circle'></div>
                  <div className='service-title fs-20 bold py-3'>Digital Marketing</div>
                  <div className='service-desc spacegrotesk'>
                    <img src={require('../../assets/images/dm.jpg')}  className='img-fluid' alt='digital marketing' title='digital marketing'/>
                  </div>
                </div>
              </Col>
              <Col lg="4" className='py-3'>
                <div className='service-box'>
                <div className='service-icon'>
                  <img src={require('../../assets/images/maintain.png')}  className='img-fluid' alt='website maintainence' title='website maintainence'/>
                </div>
                <div className='go-link'><GoArrowUpRight /> </div>
                <div className='web-circle'></div>
                  <div className='service-title fs-20 bold py-3'>Web Maintainence</div>
                  <div className='service-desc spacegrotesk'>
                    <img src={require('../../assets/images/maintain.jpg')}  className='img-fluid' alt='website maintainence' title='website maintainence'/>
                  </div>
                </div>
              </Col>
              <Col lg="4" className='py-3'>
                <div className='service-box'>
                <div className='service-icon'>
                  <img src={require('../../assets/images/host.png')}  className='img-fluid' alt='website hosting and domain' title='website hosting and domain'/>
                </div>
                <div className='go-link'><GoArrowUpRight /></div>
                <div className='web-circle'></div>
                <div className='service-title fs-20 bold py-3'>Domain & Hosting</div>
                  <div className='service-desc spacegrotesk'>
                    <img src={require('../../assets/images/hosting.jpg')}  className='img-fluid' alt='website hosting and domain' title='website hosting and domain'/>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
      </div>
       </>
    </>
  )
}

export default OurService