import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../contactus/contactus.css";
import { GrMapLocation } from "react-icons/gr";
import Forms from "../pagecomponents/Forms";
import { MdOutlineMail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
const ContactUs = () => {
  return (
    <>
      <div className="bg-contact bg-overlay ">
        <Container className="z">
          <Row>
            <Col md={12}>
              <h2 className="text-center title bold">Contact Us</h2>
            </Col>
          </Row>
        </Container>
      </div>
      <>
        <div className="padding ">
          <Container className="contactus">
            <Row>
              <Col md={7}>
                <div className="contactus-left spacegrotesk pb-3">
                  <div className="fs-50">
                    Get in Touch with <br />{" "}
                    <span className="com"> Zentexus</span>
                  </div>
                  <p className="fs-20">
                    Feel free to connect with us for any of your needs regarding
                    our services. Our support team is ready to solve any of your
                    issues. Just push a text to us and we will get back to you
                    immediately.
                  </p>
                </div>
                <hr />
                <div className="py-4">
                  <div className="d-flex align-self-center fs-20">
                    <div className="me-4">
                      <GrMapLocation color="rgba(9, 42, 5, 0.94)" />
                    </div>
                    <div className="spacegrotesk"> Virudhungar , Tamilnadu</div>
                  </div>
                </div>
                <div className="py-4">
                  <div className="d-flex align-self-center fs-20">
                    <div className="me-4">
                      <MdOutlineMail color="rgba(9, 42, 5, 0.94)" />
                    </div>
                    <div className="spacegrotesk"> info@zentexus.com</div>
                  </div>
                </div>
                <div className="py-4">
                  <div className="d-flex align-self-center fs-20">
                    <div className="me-4">
                      <FaPhoneAlt color="rgba(9, 42, 5, 0.94)" />
                    </div>
                    <div className="spacegrotesk">+91 90251 48394</div>
                  </div>
                </div>
              </Col>
              <Col lg="5">
                <Forms />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    </>
  );
};

export default ContactUs;
