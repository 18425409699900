import React from 'react'
import { TextArea, TextInputform } from '../../components/forms/TextInputform'
import Buttons from '../../components/button/Buttons'

const Forms = () => {
  return (
    <div>
             <div className='enquiry-form'>
                    <div className='py-1'>
                      <TextInputform labelname={<>Name</>}/> 
                    </div>
                    <div className='py-1'>
                      <TextInputform labelname={<>Mobile No.</>}/> 
                    </div>
                    <div className='py-1'>
                      <TextInputform labelname={<>Email</>}/> 
                    </div>
                    <div className='py-1'>
                      <TextArea labelname={<>Enquiry Your Subject</>}/> 
                    </div>
                    <div className='py-3'>
                        <Buttons className="w-100" label={<>Connect Today with Zentexus</>}/>
                    </div>

                </div>
    </div>
  )
}

export default Forms